<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{title}} Ajustes Generales</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="formDias"
                    :items="diasvisibl"
                    label="Rango de días de visualizacion de pedidos" required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="formVence"
                    :items="diasvenc"
                    label="Rango de días de visualizacion de cobranzas" required
                  ></v-autocomplete>
                </v-col>
                <!--<v-col cols="12" sm="12">
                  <v-text-field label="Comentario"></v-text-field>
                </v-col>-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="cerrar()"> Cerrar </v-btn>
            <v-btn color="primary" @click="add()" text> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { ajusteDias } from '../../config/api/ajustes';
// eslint-disable-next-line import/extensions

export default {
  name: 'Ajustes-form',
  data: () => ({
    bootsap: true,
  }),
  computed: {
    ...mapState('ajustes', ['item', 'title', 'show', 'diasvisible', 'diasvence', 'diasvisibl', 'diasvenc']),
    profile() {
      return Profile.get();
    },
    formDias: {
      set(value) {
        this.$store.dispatch('ajustes/toAssignDias', value);
      },
      get() {
        return this.diasvisible;
      },
    },
    formVence: {
      set(value) {
        this.$store.dispatch('ajustes/toAssignVence', value);
      },
      get() {
        return this.diasvence;
      },
    },
    /* formAlmacen: {
      set(value) {
        this.$store.dispatch('ajustes/toAssignAlmacen', value);
      },
      get() {
        return this.almacenEjecutivoFree;
      },
    }, */
  },
  methods: {
    ...mapActions('ajustes', ['cerrar', 'add', 'toAssignDias', 'toAssignVence', 'toAssignAlmacen', 'initializingForm']),
    ...mapMutations('ajustes', ['SHOW']),
    data() {
      return {
        diasvisible: this.formDias,
        diasvence: this.formVence,
        // almacen: this.formAlmacen,
      };
    },
    async add() {
      if (this.validate()) this.response(await ajusteDias(this.data()));
      console.log(this.data());
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/RELOAD', true);
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('ajustes/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (this.formDias < 1 || this.formVence < 1) {
        this.alert('Rango de días es requerido');
        return 0;
      }
      this.data();
      return 1;
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        // almacen: this.formAlmacen,
      };
      this.$store.dispatch('ajustes/initializingForm', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
