<template>
<v-row justify="center">
    <v-container>
    <v-dialog v-model="show1" max-width="460px" persistent>
        <v-card>
        <v-toolbar color="grey" dark>
            <v-icon class='mr-2'> mdi-application </v-icon>
            {{profile.organization.name}} - tasa del dia
        </v-toolbar>
        <v-card-text>
            <v-divider></v-divider>
            <v-col cols="8" sm="0" md="0"  class="mt-4 pt-0">
              <h1 class="title mt-2 black--text">
                {{this.tasa}} Tasa BCV  ↓
              </h1>
            </v-col>
            <v-col cols="8" sm="0" md="0"  class="mt-0 pt-0">
              <v-btn @click="bcv()" text color="pink">Seleccionar Tasa BCV</v-btn>
            </v-col>
            <v-col cols="12" sm="8" md="8" class="mt-5 pt-0">
              <v-text-field class='mx-0 mb-2'
              label="Tasa de cambio"
              v-model="this.tasa"
              autofocus
              type="Number"
              color="grey darken-4"
              readonly
              >
              </v-text-field>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-divider></v-divider>
            <v-btn @click="cerrar()" text color="red">Cerrar</v-btn>
            <v-btn @click="PF()" text color="blue">Crear PedidoFI</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-container>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapMutations, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { salesorder, updatetasa, tasaBcv } from '../../config/api/salesorder';

export default {
  name: 'salesorder-tasa',
  data: () => ({
    dialog: false,
    tasa: null,
    tasa1: 0,
  }),
  computed: {
    ...mapState('salesorder', ['item', 'type']),
    ...mapState('salesorder', ['show1']),
    ...mapState('salesorder', ['salesorderid', 'toAssignId', 'tasaIndicada', 'toAssignVIA']),
    /* eslint no-return-assign: "error" */
    formId: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignId', value);
      },
      get() {
        console.log(this.salesorderid);
        return this.salesorderid;
      },
    },
    formTasaIndicada: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignTaza', value);
      },
      get() {
        return this.tasaIndicada;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('salesorder', ['cerrar', 'showTasa']),
    ...mapMutations('salesorder', ['SHOW1']),
    ...mapActions('salesorder', ['clean']),
    data() {
      return {
        newtasa: this.tasa,
        Crmid: this.formId.split('-'),
        user_id: this.profile.user.id,
        company_id: this.profile.organization.id,
        tipo: 'FI',
        validar: 0,
      };
    },
    bcv() {
      this.formTasaIndicada = this.tasa;
    },
    async PF() {
      console.log(this.profile.organization.org);
      if (this.profile.organization.org === '1002' || this.profile.organization.org === '2002') {
        if (this.type === 'PBL66' || this.type === 'PBL67') {
          this.response(await updatetasa(this.data()));
        } else {
          this.alert('No se puede facturar por la lista que tiene el pedido');
        }
      } else {
        this.response(await updatetasa(this.data()));
      }
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/URL', api.data.url);
        this.alert(api.data.message, 'success');
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', true);
      this.$store.dispatch('message/run');
    },
  },
  async created() {
    const request = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      method: 'procesados',
    };
    const item = await salesorder(request);
    this.items = item.data;
    this.loading = false;
    this.tasa = (await tasaBcv()).data;
  },
};
</script>
