import httpClient from '../axios';

const URL = 'ajustes';

const ajustes = async (data) => httpClient.post(URL, data);

const URL_DIAS = 'ajusteDias';

const ajusteDias = async (data) => httpClient.post(URL_DIAS, data);

const URL_DIASVISIBLE = 'diasvisible';

const diasvisible = async (data) => httpClient.post(URL_DIASVISIBLE, data);

const URL_PORCENTAJE = 'porcentajeVES';

const porcentajeVES = async (data) => httpClient.post(URL_PORCENTAJE, data);

export {
  ajustes,
  ajusteDias,
  diasvisible,
  porcentajeVES,
};
