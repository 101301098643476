import {
  ajustes, diasvisible,
} from '../../config/api/ajustes';

export default {

  name: 'ajustes',
  namespaced: true,
  state: {
    // ***Ajustes***
    item: [],
    formData: [],
    id: '',
    almacen: [],
    almacenEjecutivoFree: null,
    diasvisible: [],
    // dias: [15, 30, 45, 60, 120, 180, 360],
    diasvisibl: [
      { value: 15, text: '15 días' },
      { value: 30, text: '30 días' },
      { value: 45, text: '45 días' },
      { value: 60, text: '60 días' },
      { value: 90, text: '90 días' },
      { value: 120, text: '120 días' },
      { value: 180, text: '180 días' },
      { value: 360, text: '360 días' },
    ],
    diasvence: [],
    diasvenc: [
      { value: 15, text: '15 días' },
      { value: 30, text: '30 días' },
      { value: 45, text: '45 días' },
      { value: 60, text: '60 días' },
      { value: 90, text: '90 días' },
      { value: 120, text: '120 días' },
      { value: 180, text: '180 días' },
      { value: 360, text: '360 días' },
    ],
    porcentaje_ves: '',
    iva: '',
    show: false,
    show1: false,
  },
  mutations: {
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SHOW1(state) {
      state.show1 = !state.show1;
    },
    SET_DATA(state, parameter) {
      console.log(parameter.data);
      state.item = parameter.data;
    },
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_ID(state, parameter) {
      console.log(parameter.data);
      state.id = parameter.data;
    },
    SET_DIAS(state, parameter) {
      console.log(parameter);
      state.diasvisible = parameter;
    },
    SET_VENCE(state, parameter) {
      console.log(parameter);
      state.diasvence = parameter;
    },
    SET_ALMACEN(state, parameter) {
      console.log(parameter.data);
      state.almacen = parameter;
    },
    SET_PORCENTAJE(state, parameter) {
      console.log(parameter);
      state.porcentaje_ves = parameter;
    },
    SET_DATA_DIAS(state, parameter) {
      console.log(parameter);
      state.dias = parameter.data;
    },
    SET_DATA_VENCE(state, parameter) {
      console.log(parameter);
      state.dias = parameter.data;
    },
    SET_DATA_ALMACEN(state, parameter) {
      console.log(parameter.data);
      state.almacenEjecutivoFree = parameter.data;
    },
  },
  actions: {
    async data({ commit }, parameter) {
      commit('SET_DATA', await ajustes(parameter));
      // commit('SET_PRODUCTID', await getProducto(parameter));
    },
    show({ commit }) {
      commit('SHOW');
    },
    show1({ commit }) {
      commit('SHOW1');
    },
    async add({ commit }, parameter) {
      // commit('SET_DATA', await ajusteDias(parameter));
      // console.log(parameter);
      commit('SHOW');
      commit('SET_DIAS', parameter.diasvisible);
      commit('SET_VENCE', parameter.diasvence);
    },
    async agregar({ commit }, parameter) {
      commit('SHOW1');
      commit('SET_PORCENTAJE', parameter.porcentaje_ves);
    },
    cerrar({ commit }) {
      commit('SHOW', false);
      // commit('SET_IS_SHOW', false);
    },
    close({ commit }) {
      commit('SHOW1', false);
      // commit('SET_IS_SHOW', false);
    },
    toAssignDias({ commit }, parameter) {
      commit('SET_DIAS', parameter);
    },
    toAssignVence({ commit }, parameter) {
      commit('SET_VENCE', parameter);
    },
    toAssignAlmacen({ commit }, parameter) {
      commit('SET_ALMACEN', parameter);
    },
    toAssignPorcentaje({ commit }, parameter) {
      commit('SET_PORCENTAJE', parameter);
    },
    async initializingForm({ commit }, parameter) {
      commit('SET_DATA_DIAS', await diasvisible(parameter));
    },
  },
};
