<template>
  <v-app>
    <Toolbar/>
    <v-main>
    <v-container fluid class="mt-5 pt-5">
      <v-card>
        <v-card-title>
          <v-icon class='mr-2'> mdi-application </v-icon>
          {{profile.organization.name}} - Pedidos Procesados
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            label='Buscar'
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-btn
          title='Agregar'
          v-show="validar(profile.permissions,'1002')"
          class='mx-4 mb-4'
          tile
          color='grey lighten-2'
          @click='add()'
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-card class='mx-auto' width='98%'>
          <v-data-table
            :headers='header'
            :items='items'
            :search='search'
            no-data-text='no hay datos'
            :loading='loading'
            loading-text='cargando'
            sort-by='user_name'
            class='elevation-24 mytable'
            :header-props="{
              sortByText: 'Ordenar',
              class: 'title',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Pedidos Procesados por Página',
            }"
          >
            <template v-slot:[`item.acciones`]='{ item }'>
              <v-btn icon>
                <v-icon
                  class='px-auto'
                  title='Visualizar'
                  @click='show(item,item.salesorder_no)'
                  color='black'
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'Promocion' || item.sostatus === 'FacturadoNE' || item.sostatus === 'Por Contabilizar SM') & validar(profile.permissions, '1012')">
                <v-icon class='px-auto' title='Anular' @click='anular(item)' color='orange'>
                  mdi-delete-variant
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'FacturadoNE' || item.sostatus === 'Por Contabilizar SM') & item.pedidoFinanciero == null">
                <v-icon
                  class='px-auto'
                  title='Pedido Financiero'
                  @click='showTasa(item)'
                  color='success'
                >
                mdi-arrow-down-bold-circle-outline
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'FacturadoNE' && profile.organization.id == 1) & item.pedidoFlete == null & validar(profile.permissions, '1021')">
                <v-icon
                  class='px-auto'
                  title='Pedido Para el Flete'
                  @click='showFlete({item,des})'
                  color='success'
                >
                mdi mdi-truck-delivery
                </v-icon>
              </v-btn>
              <v-btn :disabled="habilitar()"
                class="px-3 ml-1" v-if="(item.sostatus === 'Por Contabilizar SM' || item.sostatus === 'Promocion') & item.nrosap == null"
                icon title='Crear Pedido SAP' @click='sap(item)'
               >
                <v-img src='system/sap.png' height='auto' width='30'></v-img>
              </v-btn>
            </template>
            <template v-slot:[`item.sostatus`]='{ item }'>
              <v-chip :color='getColor(item.sostatus)' small text-color='white'>
                {{ item.sostatus }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
        <salesorderView></salesorderView>
        <SalesorderTasa/>
        <SalesorderFlete/>
        <Historicobcv/>
      </v-card>
    </v-container>
    </v-main>
    <SetLogout/>
    <v-dialog v-model="modalAnular" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Anular de pedido
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalAnular = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea anular el pedido seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalAnular = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="anularValidate()">
            Sí, anular!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import axios from 'axios';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  salesorder, sap, updatetasa, anular,
} from '../../config/api/salesorder';
// eslint-disable-next-line import/extensions
import SalesorderView from './SalesorderView';
// eslint-disable-next-line import/extensions
import SalesorderTasa from './SalesorderTasa';
// eslint-disable-next-line import/extensions
import SalesorderFlete from './SalesorderFlete';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'salesorder',
  components: {
    SalesorderView, Toolbar, SalesorderTasa, SetLogout, SalesorderFlete, Historicobcv,
  },
  data: () => ({
    search: '',
    tasa: null,
    des: 1,
    itemSelected: null,
    modalAnular: false,
    dialog: false,
    headersMaferca: [
      // { text: 'id', value: 'salesorderid' },
      { text: 'Pedido', value: 'salesorder_no' },
      { text: 'Rif', value: 'customerno' },
      { text: 'Cliente', value: 'accountname' },
      { text: 'Creado', value: 'createdtime' },
      { text: 'Modificado', value: 'modifiedtime' },
      { text: 'Zona', value: 'zone' },
      { text: 'Monto', value: 'total' },
      { text: 'Estatus', value: 'sostatus' },
      { text: 'Acciones', value: 'acciones' },
      { text: 'Pedido SAP', value: 'nrosap' },
      { text: 'Factura SAP', value: 'factsap' },
      { text: 'Pedido Financiero', value: 'pedidoFinanciero' },
      { text: 'Pedido Flete', value: 'pedidoFlete' },
      { text: 'Tipo', value: 'type' },
    ],
    headersDefault: [
      // { text: 'id', value: 'salesorderid' },
      { text: 'Pedido', value: 'salesorder_no' },
      { text: 'Rif', value: 'customerno' },
      { text: 'Cliente', value: 'accountname' },
      { text: 'Creado', value: 'createdtime' },
      { text: 'Modificado', value: 'modifiedtime' },
      { text: 'Zona', value: 'zone' },
      { text: 'Monto', value: 'total' },
      { text: 'Estatus', value: 'sostatus' },
      { text: 'Acciones', value: 'acciones' },
      { text: 'Pedido SAP', value: 'nrosap' },
      { text: 'Factura SAP', value: 'factsap' },
      { text: 'Pedido Financiero', value: 'pedidoFinanciero' },
      { text: 'Tipo', value: 'type' },
    ],
    items: [],
    loading: true,
    bootsap: true,
  }),
  computed: {
    ...mapState('salesorder', ['item']),
    header() {
      return this.profile.organization.name === 'Maferca' ? this.headersMaferca : this.headersDefault;
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('salesorder', ['data', 'add', 'update', 'show', 'showTasa', 'showFlete']),
    getColor(sostatus) {
      switch (sostatus) {
        case 'Anulado':
          return 'black';
        case 'Factura Anulada':
          return 'orange';
        case 'FacturadoNE':
          return 'blue';
        default:
          return 'red';
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    created() {
      this.getDolar('5');
    },
    data(item) {
      this.bootsap = false;
      return {
        Crmid: item.salesorderid,
        Pedido: item.salesorder_no,
        user_id: this.profile.user.id,
        newtasa: this.apiDolar,
        company_id: this.profile.organization.id,
        tipo: 'PE',
        validar: 0,
      };
    },
    remove(item) {
      console.log(item);
    },
    anular(item) {
      this.itemSelected = item;
      this.modalAnular = !this.modalAnular;
    },
    async anularValidate() {
      if (this.itemSelected !== null) {
        this.response1(await anular(this.data(this.itemSelected)));
      }
    },
    download(item) {
      console.log(item);
    },
    async sapUpdate(item) {
      this.response(await updatetasa(this.data(item)));
    },
    async sap(item) {
      this.response(await sap(this.data(item)));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/URL', api.data.url);
        this.alert(api.data.message, 'success');

        if (api.data.url.trim() !== '') {
          this.bootsap = false;
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    response1(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', true);
      this.$store.dispatch('message/run');
    },
    habilitar() {
      return this.bootsap === false;
    },
    /* eslint no-return-assign: "error" */
    tasabcv() {
      this.dialog = true;
      axios.get('https://s3.amazonaws.com/dolartoday/data.json')
        .then((response) => (this.tasa = response.data.USD.sicad2));
      // .then(this.tasa)
      console.log(this.tasa);
    },
  },
  async mounted() {
    // eslint-disable-next-line eqeqeq
    const permissions = this.profile.permissions.find((e) => e.accion == '1001');
    if (!permissions) {
      this.$router.push('notAuthorized');
    }
    const request = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      rol: this.profile.roluser.roles_id,
      method: 'procesados',
    };
    const item = await salesorder(request);
    this.items = item.data;
    this.loading = false;
  },
};
</script>
