import {
  // customers,
  getCliente,
} from '../../config/api/customers';

export default {

  name: 'customers',

  namespaced: true,

  state: {
    item: [],
    accountname: '',
    account_no: '',
    phone: '',
    correo: '',
    industry: '',
    direction: '',
    direction2: '',
    agente_retencion: '',
    limite_credito: '',
    zona: '',
    ruta: '',
    poblacion: '',
    cod_pago: '',
    show: false,
  },
  mutations: {
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_ACCOUNTNAME(state, parameter) {
      state.accountname = parameter;
    },
    SET_ACCOUNTNO(state, parameter) {
      state.account_no = parameter;
    },
    SET_PHONE(state, parameter) {
      state.phone = parameter;
    },
    SET_EMAIL(state, parameter) {
      state.correo = parameter;
    },
    SET_DIRECCION(state, parameter) {
      state.direction = parameter;
    },
    SET_DIRECCION2(state, parameter) {
      state.direction2 = parameter;
    },
    SET_AGENTE(state, parameter) {
      state.agente_retencion = parameter;
    },
    SET_LIMITE(state, parameter) {
      state.limite_credito = parameter;
    },
    SET_ZONA(state, parameter) {
      state.zona = parameter;
    },
    SET_RUTA(state, parameter) {
      state.ruta = parameter;
    },
    SET_POBLACION(state, parameter) {
      state.poblacion = parameter;
    },
    SET_COBRO(state, parameter) {
      state.cod_pago = parameter;
    },
  },
  actions: {
    /* async data({ commit }, parameter) {
      commit('SET_DATA', await customers(parameter));
      // commit('SET_PRODUCTID', await getProducto(parameter));
    }, */
    async show({ commit }, parameter) {
      commit('SHOW');
      commit('SET_ACCOUNTNAME', parameter.accountname);
      commit('SET_ACCOUNTNO', parameter.account_no);
      commit('SET_PHONE', parameter.phone);
      commit('SET_EMAIL', parameter.correo);
      commit('SET_DIRECCION', parameter.direction);
      commit('SET_DIRECCION2', parameter.direction2);
      commit('SET_AGENTE', parameter.agente_retencion);
      commit('SET_LIMITE', parameter.limite_credito);
      commit('SET_ZONA', parameter.zona);
      commit('SET_RUTA', parameter.ruta);
      commit('SET_POBLACION', parameter.poblacion);
      commit('SET_COBRO', parameter.cod_pago);
      // commit('SET_PRODUCTID', parameter.productid);
      commit('SET_DATA', await getCliente(parameter));
    },
    cerrar({ commit }) {
      commit('SHOW', false);
      // commit('SET_IS_SHOW', false);
    },
    toAssignAccountno({ commit }, parameter) {
      console.log(parameter);
      commit('SET_ACCOUNTNO', parameter);
    },
  },
};
