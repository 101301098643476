<template>
  <v-row justify="center">
    <v-dialog v-model="show1" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{title}} Porcentaje VES</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                  v-model="formPorcentaje"
                  label="Porcentaje"
                  >
                  </v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="close()"> Cerrar </v-btn>
            <v-btn color="primary" @click="agregar()" text> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { porcentajeVES } from '../../config/api/ajustes';
// eslint-disable-next-line import/extensions

export default {
  name: 'Porcentaje',
  data: () => ({
    bootsap: true,
  }),
  computed: {
    ...mapState('ajustes', ['item', 'title', 'show1', 'porcentaje_ves']),
    profile() {
      return Profile.get();
    },
    formPorcentaje: {
      set(value) {
        this.$store.dispatch('ajustes/toAssignPorcentaje', value);
      },
      get() {
        return this.porcentaje_ves;
      },
    },
  },
  methods: {
    ...mapActions('ajustes', ['close']),
    ...mapMutations('ajustes', ['SHOW1']),
    data() {
      return {
        porcentaje_ves: this.formPorcentaje,
      };
    },
    async agregar() {
      if (this.validate()) this.response(await porcentajeVES(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/RELOAD', true);
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('ajustes/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (this.porcentaje_ves < 1) {
        this.alert('Porcentaje VES es requerido');
        return 0;
      }
      this.data();
      return 1;
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        // almacen: this.formAlmacen,
      };
      this.$store.dispatch('ajustes/initializingForm', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
